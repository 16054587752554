import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~8],
		"/about": [35],
		"/admin": [36],
		"/admin/available-topics": [37],
		"/admin/consultation": [~38],
		"/admin/manage/tutors": [~39,[7]],
		"/admin/manage/tutors/invite": [~41,[7]],
		"/admin/manage/tutors/[id]": [~40,[7]],
		"/(payment)/cancel": [23,[5]],
		"/(payment)/checkout/booking/success": [~25,[5]],
		"/(payment)/checkout/booking/[id]": [~24,[5]],
		"/(tutors-and-courses)/consult": [~27],
		"/(tutors-and-courses)/consult/join-call/[bookingId]": [~29,[],[6]],
		"/(tutors-and-courses)/consult/my-booking": [~30],
		"/(tutors-and-courses)/consult/my-booking/[bookingId]": [~31],
		"/(tutors-and-courses)/consult/my-consultation": [~32],
		"/(tutors-and-courses)/consult/[id]": [~28],
		"/(tutors-and-courses)/course": [33],
		"/(tutors-and-courses)/course/[id]": [34],
		"/(auth)/login": [~9,[2]],
		"/(legals)/privacy-policy": [21,[4]],
		"/(auth)/profile": [10,[2]],
		"/(auth)/register": [11,[2]],
		"/(auth)/register/account-set-up": [~12,[2]],
		"/(auth)/register/email": [~13,[2]],
		"/(auth)/register/email/confirm-your-email": [~14,[2]],
		"/(auth)/register/tutor-setup": [~15,[2]],
		"/(auth)/register/tutor-setup/done": [16,[2]],
		"/(auth)/reset-password": [~17,[2]],
		"/(auth)/reset-password/[id]": [~18,[2]],
		"/(auth)/settings": [~19,[2,3]],
		"/(auth)/settings/profile": [~20,[2,3]],
		"/(payment)/success": [26,[5]],
		"/(legals)/terms-of-service": [22,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';